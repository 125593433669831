import Login from '@/components/Login.vue'
import CreateOffer from '@/CreateOffer.vue'
import OfferCenter from '@/OfferCenter.vue'
import OfferKz from '@/OfferKz.vue'
import OfferTraid from '@/OfferTraid.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Agreements from "./Agreements.vue"
import AllClients from './AllClients.vue'
import AllOffers from './AllOffers'
import CheckFirm from './CheckFirm.vue'
import CompanyRequests from "./CompanyRequests.vue"
import IncomingCalls from "./IncomingCalls.vue"
import PriceList from './PriceList.vue'
import store from './store/index'
import TaskList from './TaskList.vue'
import Technics from "./Technics.vue"
import vCard from "./vCard.vue"

Vue.use(VueRouter)

const routes = [
    { path: '/', component: AllOffers, meta: { title: "Главная страница", isNotForDealer: true } },
    { path: '/create-offer', component: CreateOffer, meta: { isNotForDealer: true } },
    { path: '/login/', component: Login, name: "Login" },
    { path: '/task-list/', component: TaskList, meta: { isNotForDealer: true } },
    { path: '/check-firm/', component: CheckFirm, name: "CheckFirm" },
    { path: '/company-requests/', component: CompanyRequests, name: "CompanyRequests" },
    { path: '/all-clients/', component: AllClients },
    { path: '/incoming-calls/', component: IncomingCalls, meta: { isNotForDealer: true } },
    { path: '/agreements/', component: Agreements, meta: { isNotForDealer: true } },
    { path: '/technics/', component: Technics },
    { path: '/kz', component: OfferKz, meta: { title: "Главная страница", isNotForDealer: true } },
    { path: '/traid', component: OfferTraid, meta: { title: "Главная страница", isNotForDealer: true } },
    { path: '/tr-center', component: OfferCenter, meta: { title: "Главная страница", isNotForDealer: true } },
    { path: '/vcf', component: vCard, meta: { title: "Контакт", isNotForDealer: true } },
    { path: '/price-list', component: PriceList, meta: { title: "Прайс лист", isNotForDealer: false } },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    const isNotForDealer = to.matched.slice().reverse().some(r => r.meta && r.meta.isNotForDealer);
    let isDiler = store.getters['isDiler'];
    if (!isDiler || !isNotForDealer) {
        next();
    } else {
        next({ name: 'CheckFirm' });
    }
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router