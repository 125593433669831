<template>
	<div
		v-if="showAll"
		class="create-offer"
		v-loading.fullscreen.lock="fullscreenLoading"
	>
		<div v-if="!isViewMode" class="select-model">
			<v-select
				v-model="category"
				label="name"
				:options="categories"
				placeholder="Выберите категорию"
			></v-select>
			<v-select
				v-if="computedBrands.length > 0 || brand !== null"
				v-model="brand"
				label="name"
				:options="computedBrands"
				placeholder="Выберите бренд"
			></v-select>
			<v-select
				v-if="computedModels.length > 0 || model !== null"
				@input="onChangeModel"
				v-model="model"
				label="product_model"
				:options="computedModels"
				placeholder="Выберите модель"
			></v-select>
			<v-select
				v-if="masts.length > 0 || mast !== null"
				@input="updateMast"
				v-model="mast"
				label="name"
				:options="masts"
				placeholder="Выберите мачту"
			></v-select>
			<v-select
				v-if="branches.length > 0"
				v-model="branch"
				label="name"
				:options="branches"
				placeholder="Выберите филиал"
			></v-select>
			<el-button v-show="false" :disabled="disableButton" icon="el-icon-plus"
				>Создать</el-button
			>
		</div>
		<el-tabs
			v-if="hasData && !isViewMode"
			class="tab-companies"
			type="card"
			v-model="currentTabCompany"
			@tab-click="onChangeTabCompany"
		>
			<el-tab-pane name="1" label="Закрепленный контрагент">
				<v-select
					v-if="firms.length > 0"
					v-model="firm"
					label="firm"
					:options="firms"
					placeholder="Выберите контрагента"
					@input="onChangeFirm"
					:clearable="false"
				></v-select>
				<strong v-else>Нет закрепленных контрагентов</strong>
			</el-tab-pane>
			<el-tab-pane name="2" label="Новая компания">
				<div v-if="!isSelectCompany">
					<el-autocomplete
						class="select-new-company"
						v-model="searchINN"
						:trigger-on-focus="false"
						:fetch-suggestions="findById"
						:hide-loading="true"
						@select="handleSelectNewCompany"
						size="medium"
						style="width: 100%; max-width: 100%"
						placeholder="Введите ИНН или ОГРН"
					></el-autocomplete>
				</div>
				<div v-else>
					<strong>{{ nameCompany }}</strong>
					<el-button
						class="clean-new-company"
						type="danger"
						icon="el-icon-delete"
						circle
						@click="cleanNewCompany"
					></el-button>
				</div>
			</el-tab-pane>
		</el-tabs>
		<div class="mobile-forms" v-if="hasData && !isViewMode">
			<div>
				<span>КП действительно до:</span>
				<el-date-picker
					v-model="dateBefore"
					type="date"
					format="dd.MM.yyyy"
					value-format="dd.MM.yyyy"
					size="mini"
					placeholder="Выберите день"
					:picker-options="pickerOptions"
				>
				</el-date-picker>
			</div>
			<div>
				<span>Дополнительное оснащение:</span>
				<el-input type="textarea" autosize placeholder="" v-model="equipment" />
			</div>
			<div>
				<span>Условия поставки:</span>
				<el-input type="textarea" autosize placeholder="" v-model="delivery" />
			</div>
			<div>
				<span>Гарантия:</span>
				<el-input type="textarea" autosize placeholder="" v-model="guarantee" />
			</div>
			<div>
				<span>Срок поставки:</span>
				<el-input
					type="textarea"
					autosize
					placeholder=""
					v-model="deliveryTime"
				/>
			</div>
			<div>
				<span>Условия оплаты:</span>
				<el-input type="textarea" autosize placeholder="" v-model="payment" />
			</div>
			<div>
				<span>Цена :</span>
				<!-- <el-input type="text" v-model="price" maxlength="99" show-word-limit /> -->
				<el-input-number
					v-model="price_integer"
					:min="1000"
					:max="100000000"
					:controls="false"
					size="small"
				></el-input-number>
			</div>
			<div>
				<span>Комментарий к цене:</span>
				<el-input
					placeholder=""
					v-model="price_comment"
					maxlength="45"
					show-word-limit
				/>
			</div>
			<div>
				<v-select
					v-if="currencies.length > 0"
					v-model="currency"
					label="full_name"
					:options="currencies"
					placeholder="Выберите валюту"
				></v-select>
			</div>
			<div>
				<el-switch v-model="isVat" active-text="С НДС" inactive-text="Без НДС">
				</el-switch>
			</div>
		</div>
		<template v-if="hasData">
			<div class="create-offer-header">
				<el-button
					v-if="!isViewMode"
					class="button-create"
					@click="SentAndSave"
					icon="el-icon-s-opportunity"
					type="primary"
					size="small"
					:disabled="isValidData"
					>Создать PDF</el-button
				>
				<el-button
					v-if="isViewMode"
					class="button-create"
					@click="printPdf($data)"
					icon="el-icon-s-opportunity"
					type="primary"
					size="small"
					>Печать PDF</el-button
				>
				<el-button
					v-if="!isViewMode"
					@click="isEdit = !isEdit"
					type="success"
					:icon="isEditButtonIcon"
					size="small"
					>{{ isEditButton }}</el-button
				>
				<vFeedBack :obj="$data" :baseUrl="baseUrl" />
			</div>
			<div class="container-pdf" :class="{ containerPdfEdit: isEdit }">
				<div class="container">
					<div class="header">
						<div class="header-left">
							<img class="logo" src="/assets/images/logo.png" alt="" />
						</div>
						<div class="header-right">
							<div class="title-container">
								<span class="title"
									>Коммерческое предложение № {{ number }}</span
								>
								<span class="about"
									><strong>ООО «Тракресурс-Регион» </strong
									><span class="margin-l-r"> для </span
									><strong>{{ nameCompany }}</strong></span
								>
								<span class="about"
									>Действителен до:
									<template v-if="!isEdit">{{ dateBefore }}</template>
									<el-date-picker
										v-else
										v-model="dateBefore"
										type="date"
										format="dd.MM.yyyy"
										value-format="dd.MM.yyyy"
										size="mini"
										placeholder="Выберите день"
										:picker-options="pickerOptions"
									>
									</el-date-picker
								></span>
							</div>
							<div v-if="branch !== null" class="branch-container">
								<div class="branch-location">
									<img class="logo" src="/assets/images/location.png" alt="" />
									<span>{{ branch.address }}</span>
								</div>
								<div class="branch-phone">
									<img class="logo" src="/assets/images/phone.png" alt="" />
									<span>{{ branch.number }}</span>
								</div>
								<div class="branch-mail">
									<img class="logo" src="/assets/images/mail.png" alt="" />
									<span>{{ branch.mail }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="main">
						<div class="main-first">
							<div class="main-first__left" style="position: relative">
								<div class="brand-container">
									<img class="logo" :src="baseUrl + productBrandLogo" alt="" />
								</div>
								<div v-html="productBrandContent" class="brand-content"></div>
							</div>
							<div class="main-first__right">
								<img :src="baseUrl + productPhoto" alt="" />
							</div>
						</div>
						<div class="attributes-container" style="position: relative">
							<span class="product-name">{{ productName }}</span>
							<!-- <img
								src="/assets/images/youtube.svg"
								alt=""
								style="
									position: absolute;
									top: -1px;
									right: 16px;
									z-index: 2;
									width: 40px;
									height: auto;
								"
							/> -->
							<div
								v-if="youtube != ''"
								ref="canvas"
								style="position: absolute; top: -15px; right: -4px; z-index: 1"
							></div>
							<span class="attributes-title">характеристики:</span>
							<div v-if="!isEdit" class="attributes-list">
								<template v-for="(item, index) in attributes">
									<div
										:key="index"
										v-if="
											item[1] !== undefined && item[1].length > 0 && index < 24
										"
										class="attributes-item"
									>
										<div v-if="!isEdit" class="attributes-key">
											<p>{{ item[0] }}:</p>
										</div>
										<input
											v-else
											v-model="item[0]"
											type="text"
											class="attribute-input-key"
										/>
										<div class="attributes-dots"></div>
										<div v-if="!isEdit" class="attributes-value">
											<p>{{ item[1] }}</p>
										</div>
										<input
											v-else
											v-model="item[1]"
											type="text"
											class="attribute-input-item"
										/>
									</div>
								</template>
							</div>
							<div v-if="isEdit" class="attributes-list">
								<template v-for="(item, index) in attributes">
									<div
										:key="index"
										v-if="item[1].length > 0 && index < 24"
										class="attributes-item"
									>
										<span v-if="!isEdit" class="attributes-key"
											>{{ item[0] }}:</span
										>
										<input
											v-else
											v-model="item[0]"
											type="text"
											class="attribute-input-key"
										/>
										<div class="attributes-dots"></div>
										<span v-if="!isEdit" class="attributes-value"
											>{{ item[1] }}
										</span>
										<input
											v-else
											v-model="item[1]"
											type="text"
											class="attribute-input-item"
										/>
										<el-button
											@click="removeEl(index)"
											class="button-delete"
											type="danger"
											icon="el-icon-delete"
											circle
											size="mini"
										></el-button>
									</div>
								</template>
							</div>
							<el-button
								v-if="isEdit"
								@click="addEl"
								:disabled="attributes.length > 25"
								type="success"
								circle
								size="mini"
								icon="el-icon-plus"
							></el-button>
						</div>
						<div class="equipment">
							<span class="equipment-title">Дополнительное оснащение:</span>
							<p v-if="!isEdit" class="equipment-content">{{ equipment }}</p>
							<p v-else>
								<input
									v-model="equipment"
									type="text"
									class="equipment-content"
								/>
							</p>
						</div>
						<div class="line"></div>
						<div class="footer">
							<div class="footer-row">
								<div class="supply">
									<strong class="title-flex">Условия поставки:</strong>
									<span v-if="!isEdit">{{ delivery }}</span>
									<input v-else v-model="delivery" type="text" />
								</div>
								<div class="service">
									<strong class="title-flex">Гарантия:</strong>
									<span v-if="!isEdit">{{ guarantee }}</span>
									<input v-else v-model="guarantee" type="text" />
								</div>
							</div>
							<div class="footer-row footer-row__second">
								<div class="footer-col footer-col__first">
									<div>
										<span class="strong">Срок поставки: </span>
										<span v-if="!isEdit">{{ deliveryTime }}</span>
										<input v-else v-model="deliveryTime" type="text" />
									</div>
									<span class="price">Цена: {{ resultPrice }} {{ vat }}</span>
								</div>
								<div class="footer-col">
									<span class="strong">Условия оплаты: </span>
									<span v-if="!isEdit">{{ paymentResult }}</span>
									<input v-else v-model="payment" type="text" />
								</div>
							</div>
							<div class="footer-row__manager">
								<span class="manager-name"
									>Ваш персональный менеджер
									<strong>{{ managerName }}:</strong></span
								>
								<div class="manager-phone">
									<img class="logo" src="/assets/images/phone.png" alt="" />
									<span>{{ managerPhone }}</span>
								</div>
								<div class="manager-mail">
									<img class="logo" src="/assets/images/mail.png" alt="" />
									<span>{{ managerMail }}</span>
								</div>
							</div>
							<div class="footer-warning">
								<span
									>Настоящее коммерческое предложение не является офертой и не
									обязывает ООО «Тракресурс-Регион» к исполнению, окончательные
									условия сотрудничества закрепляются сторонами в
									договоре.</span
								>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="productContentHtml !== null && productContentHtml.length > 2"
					class="container"
				>
					<div class="custom-page" v-html="productContentHtml"></div>
				</div>
				<div
					v-if="adaptive_content !== null && adaptive_content.length > 0"
					class="container"
				>
					<div class="description-page">
						<div v-for="(item, index) in adaptive_content" :key="index">
							<div
								v-if="item.type == 'center_image'"
								class="description-page__one-image"
							>
								<img :src="baseUrl + item.value" alt="" />
							</div>
							<div
								v-if="item.type == 'html_content'"
								class="description-page__html-content"
								v-html="item.value"
							></div>
							<div
								v-if="item.type == 'two_image'"
								class="description-page__two-image"
							>
								<img :src="baseUrl + item.value.first" alt="" />
								<img :src="baseUrl + item.value.second" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import vFeedBack from '@/components/vFeedBack.vue'
import { handelerErrors } from '@/plugins/utils'
import { ADRESS } from '@/store/actions/auth'
import axios from 'axios'
import moment from 'moment'
import QRCodeStyling from 'qr-code-styling'
import Fetch from './mixins/Fetch'

export default {
	name: 'CreateOffer',
	components: { vFeedBack },
	mixins: [Fetch],
	data() {
		return {
			activities: '',
			categories: [],
			category: null,
			brands: [],
			brand: null,
			models: [],
			model: null,
			masts: [],
			mast: null,
			number: '',
			managerName: '',
			managerPhone: '',
			managerMail: '',
			nameCompany: '',
			dateBefore: '',
			equipment: '',
			delivery: '',
			deliveryTime: '',
			payment: '',
			guarantee: '',
			price: '',
			price_comment: '',
			productName: null,
			productBrandContent: null,
			productBrandLogo: null,
			productPhoto: null,
			attributes: null,
			hasData: false,
			isEdit: false,
			branches: [],
			branch: null,
			user_id: '',
			showAll: false,
			oldAttributes: null,
			searchINN: '',
			timeout: null,
			isSelectCompany: false,
			newCompany: null,
			currentTabCompany: '1',
			fullscreenLoading: false,
			firms: [],
			firm: null,
			loading: false,
			productContentHtml: '',
			isViewMode: false,
			offer_id: '',
			pickerOptions: {
				disabledDate(time) {
					const dateMonth = new Date()
					dateMonth.setTime(Date.now() + 3600 * 1000 * 24 * 31)
					return time.getTime() < Date.now() || time.getTime() > dateMonth
				},
				shortcuts: [
					{
						text: '1 недели',
						onClick(picker) {
							const date = new Date()
							date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
							picker.$emit('pick', date)
						},
					},
					{
						text: '2 недели',
						onClick(picker) {
							const date = new Date()
							date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
							picker.$emit('pick', date)
						},
					},
					{
						text: '3 недели',
						onClick(picker) {
							const date = new Date()
							date.setTime(date.getTime() + 3600 * 1000 * 24 * 21)
							picker.$emit('pick', date)
						},
					},
					{
						text: '4 недели',
						onClick(picker) {
							const date = new Date()
							date.setTime(date.getTime() + 3600 * 1000 * 24 * 28)
							picker.$emit('pick', date)
						},
					},
				],
			},
			price_integer: 1000,
			currency: null,
			currencies: [],
			isVat: true,
			adaptive_content: null,
			qrCode: null,
			youtube: '',
		}
	},
	computed: {
		disableButton() {
			return (
				this.category == null ||
				this.brand == null ||
				this.model == null ||
				this.branch == null
			)
		},
		isEditButton() {
			return !this.isEdit ? 'Редактировать' : 'Посмотреть'
		},
		isEditButtonIcon() {
			return !this.isEdit ? 'el-icon-edit' : 'el-icon-view'
		},
		isValidData() {
			return (
				this.nameCompany.length < 2 ||
				this.dateBefore.length < 2 ||
				this.currency == null
			)
		},
		resultPrice() {
			return `${this.serializeNumber(this.price_integer, this.currency)} ${
				this.price_comment
			}`
		},
		vat() {
			return this.isVat ? 'с НДС' : 'без НДС'
		},
		paymentResult() {
			let in_currency =
				'Оплата осуществляется по курсу ЦБ РФ на дату платежа, но не менее 65 рублей за 1$. '
			return this.currency !== null &&
				this.currency !== undefined &&
				this.currency.id === 1
				? this.payment + ' ' + in_currency
				: this.payment
		},
		computedBrands() {
			if (
				this.models !== undefined &&
				this.brands !== undefined &&
				this.brands.length > 0
			) {
				let brands_id = [...new Set(this.computedModels.map(a => a.brand))]
				let brands = [...this.brands]
				if (this.category !== null) {
					brands = brands.filter(item => brands_id.includes(item.id))
				}
				return brands
			} else {
				return []
			}
		},
		computedModels() {
			if (this.models !== undefined && this.models.length > 0) {
				let models = [...this.models]
				if (this.category !== null) {
					models = models.filter(item => item.category === this.category.id)
				}
				if (this.brand !== null) {
					models = models.filter(item => item.brand === this.brand.id)
				}
				return models
			} else {
				return []
			}
		},
	},
	methods: {
		fetchData() {
			this.clearData()
			this.user_id = localStorage.getItem('used-id')
			this.activities = localStorage.getItem('user-token')
			if (this.user_id !== null) {
				this.showAll = true
			}
			this.setDefaultDate()
			this.getAllContent()
			this.getListFirm()
		},
		getAllContent() {
			axios.get(`${this.baseUrl}/api-crm/get-all-models/`).then(response => {
				this.categories = response.data.categories
				this.brands = response.data.brands
				this.models = response.data.products
				this.branches = response.data.branches
				this.currencies = response.data.currencies
				this.currency = this.currencies.find(el => el.name == 'RUB')
				let parameter = this.findGetParameter('open-offer')
				let isEdit = this.findGetParameter('is-edit') === 'true'
				if (parameter !== null) {
					this.isViewMode = !isEdit
					this.getOffer(parameter)
					this.offer_id = parameter
				}
			})
		},
		sortingAttr(attr) {
			var arrAttr, attributes, colLeftLength, n
			attributes = [...attr]
			n = 0
			colLeftLength = 0

			if (attributes.length % 2 === 1) {
				colLeftLength = Math.ceil(attributes.length / 2)
				n = attributes.length - colLeftLength + 1
			} else {
				colLeftLength = Math.ceil(attributes.length / 2)
				n = attributes.length - colLeftLength
			}

			arrAttr = []

			for (
				var element, _pj_c = 0, _pj_a = attributes, _pj_b = _pj_a.length;
				_pj_c < _pj_b;
				_pj_c += 1
			) {
				element = _pj_a[_pj_c]
				arrAttr.push(element)

				if (attributes.length === arrAttr.length) {
					break
				}

				arrAttr.push(attributes[n])
				n = n + 1

				if (attributes.length === arrAttr.length) {
					break
				}
			}
			return arrAttr
		},
		onChangeModel() {
			if (this.model !== null) {
				this.adaptive_content = null
				axios({
					method: 'get',
					url: `${this.baseUrl}/api-crm/get-model-content/`,
					params: {
						product: this.model.product_model,
					},
				})
					.then(response => {
						this.adaptive_content = response.data.adaptive_content
						this.productName = response.data.productName
						this.productBrandContent = response.data.productBrandContent
						this.productBrandLogo = response.data.productBrandLogo
						this.productPhoto = response.data.productPhoto
						this.youtube = response.data.youtube
						this.productContentHtml =
							'content' in response.data ? response.data.content : null
						this.masts = response.data.masts
						const arr = response.data.attributes
						this.attributes = arr.filter(el => {
							return (
								el[0] !== null &&
								el[1] !== null &&
								el[0].length > 0 &&
								el[1].length > 0
							)
						})
						this.attributes = this.sortingAttr(this.attributes)
						this.addMast(this.attributes)
						this.hasData = true
						this.getLocalStorage()
						this.$nextTick(() => {
							if (!this.qrCode) {
								this.qrCode = new QRCodeStyling({
									width: 80,
									height: 80,
									type: 'svg',
									data: `youtu.be/${this.youtube}`,
									image: '/assets/images/youtube.svg',
									margin: -2,
								})
								this.qrCode.append(this.$refs.canvas)
							} else {
								this.qrCode.update({
									data: `youtu.be/${this.youtube}`,
									image: '/assets/images/youtube.svg',
								})
							}
						})
					})
					.catch(err => {
						console.log(err)
						this.$notify.error({
							title: 'Ошибка',
							message: err,
						})
					})
			}
		},
		SentAndSave() {
			this.attributes = this.attributes.filter(function (value, index, arr) {
				return index < 24
			})

			let data = {
				category: this.category,
				brand: this.brand,
				model: this.model,
				branch: this.branch,
				nameCompany: this.nameCompany,
				dateBefore: this.dateBefore,
				equipment: this.equipment,
				delivery: this.delivery,
				deliveryTime: this.deliveryTime,
				payment: this.payment,
				guarantee: this.guarantee,
				price: this.price,
				currency: this.currency,
				isVat: this.isVat,
				price_integer: this.price_integer,
				attributes: this.attributes,
				mast: this.mast,
				companyUik: this.firm !== null ? this.firm['УИК'] : null,
				companyInn:
					this.firm !== null
						? this.firm['ИНН']
						: this.newCompany !== null
						? this.newCompany['data']['inn']
						: '',
				companyKpp:
					this.firm !== null
						? this.firm['КПП']
						: this.newCompany !== null
						? this.newCompany['data']['kpp'] !== undefined
							? this.newCompany['data']['kpp']
							: ''
						: '',
				productBrandContent: this.productBrandContent,
				productBrandLogo: this.productBrandLogo,
				productName: this.productName,
				productPhoto: this.productPhoto,
				managerName: this.managerName,
				managerPhone: this.managerPhone,
				managerMail: this.managerMail,
				adaptive_content: this.adaptive_content,
			}
			data.price = this.resultPrice
			axios({
				method: 'post',
				url: ADRESS + 'createoffer',
				data: data,
				headers: {
					Authorization: this.activities,
				},
			})
				.then(response => {
					data.number = response.data.НомерКП
					this.printPdf(data)
				})
				.catch(err => {
					console.log(err)
					handelerErrors(err)
				})
		},
		printPdf(data) {
			const loading = this.$loading({
				lock: true,
				spinner: 'el-icon-loading',
			})
			const headers = {
				'content-type': 'application/json',
			}
			axios
				.post('/print-pdf/', data, {
					headers: headers,
					responseType: 'blob',
				})
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'file.pdf')
					document.body.appendChild(link)
					link.click()
					loading.close()
				})
				.catch(err => {
					console.log(err)
					loading.close()
					this.$notify.error({
						title: 'Ошибка',
						message: err,
					})
				})
		},
		getOffer(offer_id) {
			axios.get(`${ADRESS}get-offer?id=` + offer_id).then(response => {
				this.hasData = true
				this.nameCompany = response.data.nameCompany
				this.companyInn = response.data.companyInn
				this.category = this.categories.find(
					item => item.name === response.data.category
				)
				this.brand = this.brands.find(item => item.name === response.data.brand)
				this.model = this.models.find(
					item => item.product_model === response.data.model
				)
				this.currency = this.currencies.find(
					el => el.name == response.data.currency
				)
				this.branch = this.branches.find(el => el.name == response.data.branch)
				this.managerName = response.data.managerName
				this.managerPhone = response.data.managerPhone
				this.managerMail = response.data.managerMail
				this.dateBefore = moment(
					response.data.dateBefore,
					'DD.MM.YYYY hh:mm'
				).format('DD.MM.YYYY')
				this.equipment = response.data.equipment
				this.delivery = response.data.delivery
				this.deliveryTime = response.data.deliveryTime
				this.payment = response.data.payment
				this.guarantee = response.data.guarantee
				this.price = response.data.price

				const arr = response.data.attributes
				this.attributes = arr.filter(el => {
					return (
						el[0] !== null &&
						el[1] !== null &&
						el[0].length > 0 &&
						el[1].length > 0
					)
				})
				this.addMast(this.attributes)
				this.getContentFromModel(response.data.mast)
				if (this.isViewMode === true) {
					this.nameCompany = response.data.nameCompany
					this.number = response.data.number
				} else {
					this.number = 'XXXXXX'
				}
			})
		},
		getContentFromModel(mast) {
			axios
				.get(
					`${this.baseUrl}/api-crm/get-model-content/?product=${this.model.product_model}`
				)
				.then(response => {
					this.masts = response.data.masts
					this.mast = this.masts.find(el => el.name == mast)
					this.productName = response.data.productName
					this.productBrandLogo = response.data.productBrandLogo
					this.productBrandContent = response.data.productBrandContent
					this.productPhoto = response.data.productPhoto
					this.adaptive_content = response.data.adaptive_content
				})
		},
		findGetParameter(parameterName) {
			var result = null,
				tmp = []
			window.location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					tmp = item.split('=')
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
				})
			return result
		},
		getLocalStorage() {
			if (
				(localStorage.getItem('user-fio') !== null &&
					this.fioToFi(localStorage.getItem('user-fio')) == this.managerName) ||
				this.managerName === ''
			) {
				if (localStorage.getItem('user-fio') !== null) {
					this.managerName = this.fioToFi(localStorage.getItem('user-fio'))
				}
				if (localStorage.getItem('user-phone') !== null) {
					this.managerPhone = this.numberWithMask(
						localStorage.getItem('user-phone')
					)
				}
				if (localStorage.getItem('user-mail') !== null) {
					this.managerMail = localStorage.getItem('user-mail')
				}
			}
		},
		addEl() {
			this.attributes.push(['Атрибут' + this.attributes.length, '1'])
		},
		removeEl(index) {
			this.attributes.splice(index, 1)
		},
		fioToFi(str) {
			if (str.split(' ').length > 2) {
				const lastIndex = str.lastIndexOf(' ')
				return str.substring(0, lastIndex)
			} else {
				return str
			}
		},
		numberWithMask(num) {
			return num.replace(
				/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2}).*/,
				'$1 ($2) $3-$4-$5'
			)
		},
		addMast(ar) {
			if (this.mast !== null && ar !== null) {
				let attributes = [...ar]
				this.mast.attributes.forEach(mast_attr => {
					if (mast_attr[1] !== null) {
						let b = 5
						if (attributes.some(item => item[0] == mast_attr[0])) {
							let attr_index = attributes.findIndex(
								item => item[0] == mast_attr[0]
							)
							attributes[attr_index][1] = mast_attr[1]
						} else {
							b = b + 1
							attributes.splice(b, 0, [mast_attr[0], mast_attr[1]])
						}
					}
				})
				this.attributes = attributes
			}
		},
		updateMast() {
			if (this.mast !== null && this.attributes !== null) {
				let attributes = [...this.attributes]
				this.mast.attributes.forEach(mast_attr => {
					if (mast_attr[1] !== null) {
						let b = 5
						if (attributes.some(item => item[0] == mast_attr[0])) {
							let attr_index = attributes.findIndex(
								item => item[0] == mast_attr[0]
							)
							attributes[attr_index][1] = mast_attr[1]
						} else {
							b = b + 1
							attributes.splice(b, 0, [mast_attr[0], mast_attr[1]])
						}
					}
				})
				this.attributes = attributes
			}
		},
		findById(queryString, cb) {
			clearTimeout(this.timeout)
			if (this.searchINN.length > 9) {
				this.loading = true
				this.timeout = setTimeout(() => {
					axios({
						method: 'get',
						url: `${this.baseUrl}/api-crm/find-by-id/`,
						params: { id: queryString },
					})
						.then(response => {
							this.loading = false
							const results = response.data.data
							if (response.data.data.length === 0) {
								this.$notify.error({
									title: 'Ошибка',
									message: 'Компания не найдена!',
								})
							}
							cb(results)
						})
						.catch(err => {
							this.loading = false
							handelerErrors(err)
						})
				}, 2000)
			}
		},
		handleSelectNewCompany(item) {
			this.nameCompany = item.value
			this.checkInnForDealerExclusive(item.data.inn)
			this.newCompany = item
			this.isSelectCompany = true
		},
		onChangeTabCompany() {
			this.cleanNewCompany()
			this.firm = null
		},
		getListFirm() {
			this.fullscreenLoading = true
			axios({
				method: 'get',
				url: ADRESS + 'getlistfirm',
				headers: { Authorization: this.activities },
			})
				.then(response => {
					this.firms = response.data.firms.sort((a, b) => {
						return a['firm'].localeCompare(b['firm'])
					})
					this.firm = this.firms.find(item => item['ИНН'] === this.companyInn)
					this.fullscreenLoading = false
				})
				.catch(err => {
					this.fullscreenLoading = false
					handelerErrors(err)
				})
		},
		cleanNewCompany() {
			this.nameCompany = ''
			this.searchINN = ''
			this.isSelectCompany = false
			this.newCompany = null
		},
		onChangeFirm() {
			if (this.firm !== null) {
				this.nameCompany = this.firm.firm
				this.checkInnForDealerExclusive(this.firm['ИНН'])
			}
		},
		setDefaultDate() {
			this.dateBefore = moment().add(14, 'days').format('DD.MM.YYYY')
		},
		checkInnForDealerExclusive(inn) {
			let region = inn.substr(0, 2)
			let forbidden_list_forklift = ['12', '58', '34', '76']
			let forbidden_list_dst = ['52']
			if (this.brand.name == 'TCM' || this.brand.name == 'DALIAN') {
				if (forbidden_list_forklift.some(element => element === region)) {
					this.$alert(
						`Данный регион является эксклюзивным для дилера по бренду ${this.brand.name}`,
						'Предупреждение',
						{
							confirmButtonText: 'OK',
						}
					)
				}
			} else {
				if (this.brand.name == 'LONKING') {
					if (forbidden_list_dst.some(element => element === region)) {
						this.$alert(
							`Данный регион является эксклюзивным для дилера по бренду ${this.brand.name}`,
							'Предупреждение',
							{
								confirmButtonText: 'OK',
							}
						)
					}
				}
			}
		},
		serializeNumber(number, currency) {
			return new Intl.NumberFormat('ru-RU', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
				style: 'currency',
				currency: currency ? currency.name : 'RUB',
			}).format(number)
		},
		clearData() {
			let data = {
				categories: [],
				category: null,
				brands: [],
				brand: null,
				models: [],
				model: null,
				masts: [],
				mast: null,
				number: '',
				managerName: '',
				managerPhone: '',
				managerMail: '',
				nameCompany: '',
				dateBefore: '',
				equipment: '',
				delivery: '',
				deliveryTime: '',
				payment: '',
				guarantee: '',
				price: '',
				productName: null,
				productBrandContent: null,
				productBrandLogo: null,
				productPhoto: null,
				attributes: null,
				hasData: false,
				isEdit: false,
				branches: [],
				branch: null,
				user_id: '',
				showAll: false,
				oldAttributes: null,
				searchINN: '',
				timeout: null,
				isSelectCompany: false,
				newCompany: null,
				currentTabCompany: '1',
				fullscreenLoading: false,
				firms: [],
				firm: null,
				loading: false,
				productContentHtml: '',
				isViewMode: false,
				offer_id: '',
				pickerOptions: {
					disabledDate(time) {
						const dateMonth = new Date()
						dateMonth.setTime(Date.now() + 3600 * 1000 * 24 * 31)
						return time.getTime() < Date.now() || time.getTime() > dateMonth
					},
					shortcuts: [
						{
							text: '1 недели',
							onClick(picker) {
								const date = new Date()
								date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
								picker.$emit('pick', date)
							},
						},
						{
							text: '2 недели',
							onClick(picker) {
								const date = new Date()
								date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
								picker.$emit('pick', date)
							},
						},
						{
							text: '3 недели',
							onClick(picker) {
								const date = new Date()
								date.setTime(date.getTime() + 3600 * 1000 * 24 * 21)
								picker.$emit('pick', date)
							},
						},
						{
							text: '4 недели',
							onClick(picker) {
								const date = new Date()
								date.setTime(date.getTime() + 3600 * 1000 * 24 * 28)
								picker.$emit('pick', date)
							},
						},
					],
				},
				price_integer: 1000,
				currency: null,
				currencies: [],
				isVat: true,
				adaptive_content: null,
			}
			Object.assign(this.$data, data)
		},
	},
}
</script>

<style lang="scss">
.create-offer {
	.container-pdf {
		width: 793px;

		.container {
			border: 1px solid black;
		}
	}

	.select-model {
		padding: 0 20px;

		@media (max-width: 480px) {
			padding: 0 10px;
		}

		> div {
			margin: 15px 0;
			width: 80%;
			max-width: 400px;

			@media (max-width: 480px) {
				width: 100%;
				max-width: 100%;
			}
		}

		.select-model button {
			margin: 15px 0;
		}
	}

	.tab-companies {
		max-width: 440px;
		padding: 0 20px;
		margin-bottom: 30px;

		.el-tabs__content {
			overflow: visible;
		}

		.select-new-company {
			input::placeholder {
				color: #000000;
			}

			.el-input {
				max-width: 100%;
			}
		}

		.clean-new-company {
			margin-left: 15px;
			padding: 7px;
		}

		@media (max-width: 480px) {
			padding: 0 10px;
			max-width: 100%;
		}
	}

	.attributes-item input {
		width: 100px;
		font-size: 12px;
		line-height: 13px;
		height: 14px;
	}

	.attributes-item .attribute-input-key {
		min-width: 190px;
		width: 190px;
	}

	.attributes-item .attribute-input-item {
		min-width: 100px;
		width: 100px;
	}

	.containerPdfEdit .attributes-item {
		margin-bottom: 7px;
	}

	.containerPdfEdit .equipment-content {
		width: calc(100% - 35px);
	}

	.containerPdfEdit .footer-row input {
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		align-items: center;
		color: #000000;
		height: 16px;
		width: 100%;
	}

	.containerPdfEdit .title-container input {
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		height: 16px;
	}

	.containerPdfEdit .attributes-item {
		position: relative;
	}

	.containerPdfEdit .attributes-item input {
		line-height: 16px;
		height: 16px;
	}

	.create-offer-header {
		display: flex;
		height: 70px;
		width: 100%;
		min-width: 793px;
		align-items: center;
		padding-left: 20px;
	}

	.button-link {
		text-decoration: none;
		margin-left: 30px;
		margin-right: 30px;
	}

	.attributes-item p {
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 12px;
		color: #000000;
		word-wrap: break-word;
		margin-bottom: 0;
	}

	.button-delete {
		position: absolute;
		right: -23px;
		top: -1px;

		&.el-button--mini.is-circle {
			padding: 4px;
			font-size: 8px;
		}
	}

	p {
		margin-bottom: 0;
	}

	.mobile-forms {
		display: flex;
		width: 100%;
		max-width: 400px;
		flex-direction: column;
		padding: 0 20px;

		> div {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;

			span {
				margin-bottom: 5px;
			}
		}

		.el-date-editor.el-input {
			width: 100%;
			max-width: 100%;
		}

		.el-input {
			width: 100%;
			max-width: 100%;
		}

		@media (max-width: 480px) {
			display: flex;
		}
	}
}
</style>
