<template>
  <div class="agreement-view-component" v-if="agreement !== null && agreementData !== null">
    <el-dialog :title="agreement['БизнесПроцесс']" :visible.sync="dialogVisibleOuter" @closed="closeDialog" width="96%"
      :close-on-click-modal="false">
      <div class="agreement-view">
        <el-row>
          <span class="agreement-view__inline">Дата: </span>
          <strong>{{ agreement.date }}</strong>
        </el-row>
        <el-row>
          <span>Клиент:</span>
          <strong>{{ agreementData["Клиент"] }}</strong>
        </el-row>
        <el-row>
          <span>ИНН/КПП:</span>
          <strong>{{ agreementData["ИНН"] }}/{{ agreementData["КПП"] }}</strong>
        </el-row>
        <el-row v-if="agreementData['ТипСделки'] !== null">
          <span class="agreement-view__inline">ТипСделки: </span>
          <strong>{{ agreementData["ТипСделки"] }}</strong>
        </el-row>
        <el-row v-if="agreementData['Дилер'] !== null && agreementData['Дилер'].length > 0
        ">
          <span class="agreement-view__inline">Дилер: </span>
          <strong>{{ agreementData["Дилер"] }}</strong>
        </el-row>
        <el-row v-if="agreementData['Бренд'] !== null">
          <span class="agreement-view__inline">Бренд: </span>
          <strong>{{ agreementData["Бренд"] }}</strong>
        </el-row>
        <el-row v-if="agreementData['Техника'] !== null">
          <span>Техника:</span>
          <ul>
            <li v-for="(product, index) in agreementData['Техника']" :key="index">
              {{ product }}
            </li>
          </ul>
        </el-row>
        <el-row v-if="agreementData['ТехникаПодЗаказ'] !== null">
          <span>Техника под заказ</span>
          <ul>
            <li>
              Модель Техники:
              <strong>{{
                agreementData["ТехникаПодЗаказ"]["МодельТехники"]
              }}</strong>
            </li>
            <li>
              Мачта:
              <strong>{{ agreementData["ТехникаПодЗаказ"]["Мачта"] }}</strong>
            </li>
            <li>
              Вилы:
              <strong>{{ agreementData["ТехникаПодЗаказ"]["Мачта"] }}</strong>
            </li>
            <li>
              АКБ:
              <strong>{{ agreementData["ТехникаПодЗаказ"]["АКБ"] }}</strong>
            </li>
          </ul>
        </el-row>
        <el-row v-if="agreementData['ГрафикОплаты'] !== null &&
          agreementData['ГрафикОплаты'].length > 0
        ">
          <span>График оплаты:</span>
          <ul>
            <li v-for="(product, index) in agreementData['ГрафикОплаты']" :key="index">
              {{ product }}
            </li>
          </ul>
        </el-row>
        <el-row v-if="agreementData['ДополнительныеЗатраты'] !== null &&
          agreementData['ДополнительныеЗатраты'].length > 0
        ">
          <span>Дополнительные затраты:</span>
          <ul>
            <li v-for="(product, index) in agreementData['ДополнительныеЗатраты']" :key="index">
              {{ product }}
            </li>
          </ul>
        </el-row>
        <div class="agreement-view__price-block">
          <el-row v-if="agreementData['БазоваяЦена'] !== null">
            <span>Базовая цена: </span>
            <strong>{{ agreementData["БазоваяЦена"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['СкидкаОтБазовой'] !== null">
            <span>Скидка от базовой: </span>
            <strong>{{ agreementData["СкидкаОтБазовой"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['МенеджерскаяЦена'] !== null">
            <span>Менеджерская цена: </span>
            <strong>{{ agreementData["МенеджерскаяЦена"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['НаценкаОтМенеджерской'] !== null">
            <span>Наценка от менеджерской: </span>
            <strong>{{ agreementData["НаценкаОтМенеджерской"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['ВсегоДополнительныхЗатрат'] !== null">
            <span>Всего дополнительных затрат: </span>
            <strong>{{ agreementData["ВсегоДополнительныхЗатрат"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['УТС'] !== null">
            <span>УТС: </span>
            <strong>{{ agreementData["УТС"] }}</strong>
          </el-row>
          <el-row v-if="agreementData['ИтогоПоДокументу'] !== null">
            <span>Итого: </span>
            <strong>{{ agreementData["ИтогоПоДокументу"] }}
              {{ agreementData["Валюта"] }}</strong>
          </el-row>
        </div>
        <el-row v-if="agreementData['Комментарий'] !== null">
          <span>Комментарий:</span>
          <strong>{{ agreementData["Комментарий"] }}</strong>
        </el-row>
        <el-row v-if="agreement['Автор'] !== null">
          <span>Автор:</span>
          <strong>{{ agreement["Автор"] }}</strong>
        </el-row>
        <el-row v-if="agreement['Автор'] !== null">
          <span>Филиал:</span>
          <strong>{{ agreement["Автор"] }}</strong>
        </el-row>
      </div>
      <el-row>
        <el-input size="small" v-model="comment"
          :placeholder="hasErrorState ? 'Введите комментарий минимум 3 символа' : 'Введите комментарий'"
          :class="{ error: hasErrorState }" />
      </el-row>
      <el-row type="flex" justify="space-around" slot="footer">
        <el-popconfirm @confirm="confirmAgreement(true)" confirm-button-text="Да" cancel-button-text="Не согласовывать"
          icon="el-icon-info" icon-color="red" title="Согласовать?">
          <el-button size="small" type="success" slot="reference"><i class="el-icon-success" /> Согласовать</el-button>
        </el-popconfirm>
        <el-popconfirm @confirm="confirmAgreement(false)" :disabled="comment.length < 3" confirm-button-text="Да"
          cancel-button-text="Не отклонять" icon="el-icon-info" icon-color="red" title="Отклонить?">
          <el-button :class="hasErrorState ? 'shake' : ''" @click="setErrorState" size="small" type="danger"
            slot="reference"><i class="el-icon-error" /> Отклонить</el-button>
        </el-popconfirm>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { ADRESS } from "@/store/actions/auth";
import downloadjs from "downloadjs";
import { ref } from "vue";
export default {
  name: "vAgreementView",
  data() {
    return {
      hasErrorState: false,
      dialogVisibleOuter: false,
      agreement: null,
      agreementData: null,
      comment: "",
    };
  },
  props: {
    propAgreement: Object,
    activities: String,
  },
  watch: {
    propAgreement(agreement) {
      if (agreement !== null && agreement.БизнесПроцесс === 'Предварительное закрепление техники (new)' || agreement !== null && agreement.БизнесПроцесс === 'Согласование расчета') {
          this.dialogVisibleOuter = true;
          this.agreement = agreement;
          this.getAgreementData();
      }
    },
  },
  created() { },
  mounted() { },
  computed: {},
  filters: {
    removeTime(date) {
      return moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY");
    },
  },
  methods: {
    closeDialog() {
      this.comment = "";
      this.agreement = null;
      this.agreementData = null;
      this.$emit("closeDialog");
    },
    setErrorState() {
      if (this.comment.length < 3) {
        this.hasErrorState = true
      } else {
        this.hasErrorState = false;
      }
    },
    getAgreementData() {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .get(`${ADRESS}view-task-reconciliation?uik=${this.agreement["УИК"]}`, {
          headers: { Authorization: this.activities },
        })
        .then((response) => {
          this.agreementData = response.data;
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    confirmAgreement(isAccepted) {
      const loading = this.$loading({
        lock: true,
      });

      axios({
        method: "post",
        url: ADRESS + "edit-task-reconciliation",
        data: {
          uik: this.agreement["УИК"],
          agreed: isAccepted,
          comment: this.comment,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.$notify.success({
            title: "Успешно",
            message: response.data,
          });
          this.$emit("accept", this.agreement["УИК"]);
          this.closeDialog();
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.agreement-view-component {
  .el-dialog__body {
    padding-top: 0;

    @media (max-width: 550px) {
      padding: 0 10px;
    }
  }

  .el-dialog__title {
    font-size: 16px;
    line-height: 18px;
    padding-right: 10px;
  }
}

.agreement-view {

  .vs__selected,
  .vs__search {
    font-size: 12px;
    white-space: nowrap;
  }

  ul {
    list-style-type: none;

    li {
      margin-left: 15px;
      font-weight: bold;
    }
  }

  span,
  strong,
  li {
    font-size: 12px;
    color: #000;
  }

  .el-button span {
    color: #ffffff;
  }

  .el-row {
    margin: 7px 0;

    >span {
      display: block;
    }

    .el-link span {
      font-weight: bold;
      color: #007090;
      text-decoration: underline;
    }

    >.agreement-view__inline {
      display: inline;
    }
  }

  .agreement-view__price-block {
    padding: 15px 10px;
    margin: 0 -10px;
    background-color: #fcf7e9;

    .el-row>span {
      display: inline;
    }
  }

  .agreement-view__files {
    list-style-type: none;

    li {
      font-weight: bold;
      font-size: 12px;
      color: #007090;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .el-select {
    width: 100%;
  }
}

.tab-discussions {
  .el-timeline-item__timestamp {
    font-size: 11px;
  }

  .el-card__body>* {
    font-size: 12px;
  }

  .date-reminder {
    display: block;
    margin-top: 5px;
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }

  .relation-create__row-comment .el-input {
    width: calc(100% - 37px);
    margin-right: 5px;
  }
}

.error input {
  border-color: red;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
}

@media (max-width: 550px) {
  .agreement-view-select__zero-left {
    left: 5px !important;

    span {
      font-size: 12px;
    }
  }

  .agreement-view-select {
    span {
      font-size: 12px;
    }
  }
}
</style>